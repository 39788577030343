<template>
  <v-container>
    <h1>Historial Laboral?</h1>
  </v-container>
</template>

<script>
export default {
  name: "historial-laboral",
  data() {
    return {
      loading: false
    };
  },

  created() {},

  watch: {},

  computed: {},

  mounted() {
    this.$store.commit("setNavigationDrawer", false)
  },

  methods: {}
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
